body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.scroll::-webkit-scrollbar {
  background-color: rgb(228, 228, 228);
  border-radius: 25px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #1e0079;
  border-radius: 25px;
}
.scrollbar::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
*::-webkit-scrollbar-track {
  background: transparent;
  margin: 10px 0;
}
*::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #05269183;
  border-radius: 25px;
}

.selected {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: linear-gradient(
    to bottom,
    rgb(255, 89, 0) 0%,
    rgb(255, 131, 64) 100%
  );
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, rgb(255, 89, 0) 0%, rgb(255, 131, 64) 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0px 10px 0px 10px;
}
input[type='checkbox'] {
  width: 25px;
  height: 25px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
