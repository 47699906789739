.Header {
  position: fixed;
  width: auto;
  /* background: linear-gradient(89.74deg, #618BCC 17.59%, #0B3C5D 77.33%); */
  display: flex;
  left: 230px;
  right: 0;
  flex-direction: column;
  justify-content: space-between;
}

.UserInformation__photo {
  height: 50px;
  width: 50px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1536px) {
  .Header {
    position: fixed;
    width: auto;
    /* background: linear-gradient(89.74deg, #618BCC 17.59%, #0B3C5D 77.33%); */
    display: flex;
    left: 190px;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
  }
  .UserInformation__photo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
  }
}
